import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
  useParams
} from 'react-router-dom'
import ListTable from '../../components/ListTable'
import ListFilter from '../../sections/ListFilter'
import _, { isArray, cloneDeep } from 'lodash'
import { Container, Section, SectionFixed } from '../../components/Container'
import Loader from '../../components/Loader'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import EmptyRecord from '../../sections/EmptyRecords'
import { checkUserRoleSuperUser } from '../../utils/User'
import ClientResult from '../../sections/ClientResult'
import { initalizeS3 } from '../../utils/AWS'
import {
  mergeClients,
  getIndividualClient,
  getClearBitData,
  multiDocuments,
  getAllProposalNames,
  createClient
} from '../../store/api'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { setToasterAlert } from '../../store/Common/Actions'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import InfiniteScroll from 'react-infinite-scroll-component'
import fetchClientsList from '../../store/Clients/Actions'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { ImageAvatarFallback, TagFilter } from '../../components'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import { Box, Dialog, Drawer, TextField, Zoom } from '@mui/material'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import Button2 from '../../components/Button/Button2'
import {
  CrossIcon,
  MergeIcon,
  PropertiesIcon
} from '../../components/Icons/Icons'
import Label from '@mui/icons-material/Label'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import AlphabetSearch from '../PeopleResume/AlphabetSearch'
import BulkUpdateModal from '../../components/BulkUpdateModal/BulkUpdateModal'
import GenericMergeModal from '../../components/GenericMergeModal/GenericMergeModal'
import FilterListIcon from '@mui/icons-material/FilterList'

import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Slide from '@mui/material/Slide'
import AddIcon from '@mui/icons-material/Add'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClientList = (props) => {
  const { showSearch = false, isEditable = true, isSuperUser = false } = props
  const { LIBRARY_CLIENTS_RESUME } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_CLIENTS_RESUME)

  const clientsList = useSelector((state) => state?.clients?.clientsList)
  const hasMoreData = useSelector((state) => state?.clients?.hasMoreData)
  const [alphabetKey, setAlphabetKey] = useState(null)
  const { clientId } = useParams()
  const user = useSelector((state) => state.authenticate.user)
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(null)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [showBulkModal, setShowBulkModal] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredRecord, setFilteredRecord] = useState([])
  const navigate = useNavigate()
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [currentClientData, setCurrentClientData] = useState({})
  const [topVariatonList, setTopVariatonList] = useState([])
  const [topProposalScreenshot, setTopProposalScreenshot] = useState([])
  const [topProposalScreenshotOrigin, setTopProposalScreenshotOrigin] =
    useState([])
  const [openClient, setOpenClient] = useState(false)
  const [currentClient, setCurrentClient] = useState(null)
  const [screenshotIndex, setScreenshotIndex] = useState(0)
  const [s3Obj, sets3Obj] = useState(null)
  const [originProposalNames, setOriginProposalNames] = useState([])
  const [isMerge, setIsMerge] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [isSearchReloadRequired, setIsSearchReloadRequired] = useState(false)
  const [singleData, setSingleData] = useState(null)
  const [tagState, setTagState] = useState({})
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [showClientGroupModal, setShowClientGroupModal] = useState(false)
  const [proposalList, setProposalList] = useState([])
  const [clientGroupName, setClientGroupName] = useState('')
  const [proposalNameIDList, setProposalNameIDList] = useState([])
  const [createClientLoading, setCreateClientLoading] = useState(false)

  const handleCloseClient = () => {
    setOpenClient(false)
    setLoading(true)
    navigate(ROUTES.LIBRARY_CLIENTS_RESUME)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    handleSearch('')
    initalizeData()
  }, [])

  useEffect(() => {
    const savedPage = localStorage.getItem('clientsPage')
    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      setPage(1)
      localStorage.setItem('clientsPage', '1')
    }
  }, [])

  useEffect(() => {
    if (page && !clientId) {
      fetchMoreData(page)
      localStorage.setItem('clientsPage', page)
    }
  }, [page])

  useEffect(() => {
    if (page === 1) {
      fetchMoreData(page)
    } else {
      setPage(1)
    }
  }, [alphabetKey])

  useEffect(() => {
    if (isFocused) {
      if (!clientsList && showSearch) {
        setLoading(true)
        // fetchMoreData(1)
      }
    }
  }, [clientsList, isFocused, hasMoreData, clientId, singleData])

  useEffect(() => {
    if (clientId) {
      fetchSingleData()
      setOpenClient(true)
    }
  }, [clientId])

  useEffect(() => {
    if (selectedTags) {
      fetchMoreData(page)
    }
  }, [selectedTags])

  useEffect(() => {
    const tagsValues = {}
    const tagMap = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        key_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (key_type?.includes('clientgroup')) {
        data.forEach((element) => {
          tagMap[element?.id] = {
            key,
            ...element
          }
          if (element.value) {
            if (tagsValues[key]?.values) {
              tagsValues[key].values.push({
                value: element.id,
                label: element.value
              })
            } else {
              if (!tagsValues[key]) tagsValues[key] = {}
              tagsValues[key].values = [
                {
                  value: element.id,
                  label: element.value
                }
              ]
            }
          }
          tagsValues[key] = {
            ...tagsValues[key],
            type,
            ...rest
          }
        })
      }
    })
    setTagsOptions(tagsValues)
    setTagState(tagMap)
  }, [tagsCenterStateTags])

  const fetchSingleData = async () => {
    setLoading(true)
    const res = await getIndividualClient({ client_id: clientId })
    if (res.status === 200) {
      const result = res.data
      let client = result.clients

      const { domain_id, proposal_id } = client
      const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`

      client = {
        ...client,
        thumbnail_url,
        projects: client?.data?.map((project) => {
          const { proposal_id: project_proposal_id } = project
          const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${project_proposal_id}/original/1.png`
          return {
            ...project,
            thumbnail_url
          }
        })
      }
      setLoading(false)
      setCurrentClient(client)
      setSingleData(client)
      // if (clientsList?.length > 0) {

      // } else {
      //   toast.error('Unable to fetch clients')
      // }
    } else {
      toast.error('Unable to fetch clients')
    }
  }

  const fetchMoreData = (page, tags = selectedTags) => {
    // setPage(page)
    setLoading(true)
    const tag_values = []
    const tag_dates = []
    const selectedTagsObj = tags
    selectedTagsObj &&
      Object.keys(selectedTagsObj).forEach((key) => {
        const tags = []
        let { condition, values = [] } = selectedTagsObj[key] || {}
        values = values || []
        values.forEach((item) => {
          if (item?.type?.includes('date')) {
            tag_dates.push({ ...item, condition })
          } else {
            tags.push(item.value)
          }
        })
        if (tags.length > 0) {
          tag_values.push({
            key,
            condition,
            values: tags
          })
        }
      })

    const finalTags = tag_values.filter((item) => item.key !== 'Client type')

    let propertyFilter = null

    tag_values.map((item) => {
      if (item.key === 'Client type') {
        propertyFilter = [
          {
            condition: item.condition,
            type: 'client_type',
            value: item.values[0]
          }
        ]
      }

      return null
    })

    dispatch(
      fetchClientsList(
        {
          page_num: page,
          page_size: 20,
          keyword: '',
          tags: finalTags,
          property: propertyFilter,
          alphabetical: alphabetKey
        },
        'fetch',
        (data) => {
          setRows(data?.clients)
          setLoading(false)
          setMeta({
            pageSize: data?.page_size,
            totalCount: data?.total_count,
            page: data?.page_num
          })
        }
      )
    )
  }

  const navigateOpenClient = (i, row) => {
    const clientId = row?.id
    navigate('/library/companies/' + clientId)
  }

  const columns = [
    {
      id: 'title',
      label: 'Company Name',
      accessorKey: 'title',
      children: (row) => {
        return (
          <span className="flex items-center gap-2 text-xs text-grey-800">
            <ImageAvatarFallback
              name={row?.title}
              profilePic={row?.enrichment_data?.logo}
              style={{
                fontSize: '8px',
                background: 'var(--grey-700)',
                height: '26px',
                width: '26px'
              }}
              openImage={() => {}}
              s3Obj={s3Obj}
            />
            {row?.title}
          </span>
        )
      },
      style: {
        fontFamily: 'PoppinsMedium',
        fontSize: '14px'
      }
    },
    {
      id: 'type',
      label: 'Company Type',
      accessorKey: 'type',
      children: (row) => {
        return <span>{row?.properties?.client_type?.value ?? '-'}</span>
      }
    },
    {
      id: 'tags',
      label: 'Tags',
      children: (row) => {
        return (
          <div
            className="w-full flex items-center text-xs"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedData([row.id])
            }}
          >
            <DisplayTags
              tags={row?.tags ?? []}
              setShowTagDrawer={() => setShowTagDrawer(row.id)}
            />
          </div>
        )
      }
    },
    {
      id: 'domain',
      label: 'Domain',
      accessorKey: 'domain',
      children: (row) => {
        return <span>{row?.enrichment_data?.domain ?? '-'}</span>
      }
    },
    {
      id: 'projectName',
      label: 'Project',
      accessorKey: 'title'
    },
    {
      id: 'documentLocation',
      label: 'Location',
      accessorKey: 'location'
    }
  ]

  const setInitialData = () => {
    const data = []
    clientsList &&
      clientsList.forEach((client) => {
        const el = {}
        el.id = client?.id
        el.documentName = client?.title
        el.projectName = client?.data?.project_name
        el.location = client?.data?.client_location
        el.logo = client?.enrichment_data?.logo
        el.domain = client?.enrichment_data?.domain
        data.push(el)
      })

    // setRows(data)
  }

  useEffect(() => {
    if (isArray(clientsList) && clientsList.length > 0) {
      setInitialData()
      setLoading(false)
      setFilteredRecord(clientsList)
    }
  }, [clientsList])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  const handleTagsValueChange = (key, value, mode) => {
    console.log('tags value client', key, value, mode)

    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  const refresh = () => {
    setIsMerge(false)
    setSelectedData([])
    setSelectedRowData([])
    setLoading(true)
    setPage(1)
    setSearchValue('')
    // setSelectedTags(null)
    setSelectedTags(null)
    // setSearchOnKey(false)
    fetchMoreData(1, null)
  }

  // const OpenClient = (e, i, row, list = clientsList) => {
  //   if (!list) {
  //     return
  //   }
  //   const clientId = row?.id
  //   const filteredJson = list?.filter((item) => item?.id === clientId)
  //   const thumbnail_url = filteredJson[0]?.thumbnail_url
  //   const thumbnail_url_list = filteredJson[0]?.variation_list?.map(
  //     (item) => item?.thumbnail_url
  //   )
  //   setCurrentClient(filteredJson[0])
  //   setTopProposalScreenshot([thumbnail_url, ...thumbnail_url_list])
  //   const thumbnail_url_origin =
  //     filteredJson[0]?.page_number === 1
  //       ? filteredJson[0]?.proposal_url.includes('rfx')
  //         ? 'rfx'
  //         : 'proposal'
  //       : 'resume'
  //   const thumbnail_url_list_origin = filteredJson[0]?.variation_list?.map(
  //     (item) =>
  //       item?.page_number === 1
  //         ? item?.proposal_url.includes('rfx')
  //           ? 'rfx'
  //           : 'proposal'
  //         : 'resume'
  //   )
  //   setTopProposalScreenshotOrigin([
  //     thumbnail_url_origin,
  //     ...thumbnail_url_list_origin
  //   ])
  //   setOriginProposalNames([
  //     filteredJson[0]?.proposal_name,
  //     ...filteredJson[0]?.variation_list?.map((item) => item?.proposal_name)
  //   ])
  //   setCurrentClientData({
  //     ...filteredJson[0]?.data,
  //     proposal_name: filteredJson[0]?.proposal_name,
  //     client_group_id: filteredJson[0]?.id,
  //     client_id: filteredJson[0]?.client_id
  //   })
  //   setTopVariatonList(filteredJson[0]?.variation_list)
  //   setScreenshotIndex(0)
  // }

  // const handleVariationIndexChange = (index) => {
  //   if (index >= 0) {
  //     setScreenshotIndex(index + 1)
  //     // setCurrentClientData(topVariatonList?.[index]?.data);
  //     setCurrentClientData({
  //       ...topVariatonList?.[index]?.data,
  //       proposal_name: topVariatonList?.[index]?.proposal_name
  //     })
  //     // setTopVariatonList([]);
  //   } else {
  //     setScreenshotIndex(0)
  //     // setCurrentClientData(currentClient?.data);
  //     setCurrentClientData({
  //       ...currentClient?.data,
  //       proposal_name: currentClient?.proposal_name
  //     })
  //     setTopVariatonList(currentClient?.variation_list)
  //   }
  // }

  const handleMultiTags = async (totalTags) => {
    if (selectedData.length === 0) {
      toast.error('Please select atleast one project to tag')
    } else {
      const datetags = {}
      const tags = {}
      const dateArr = {}
      const tagsArr = {}
      for (const key in totalTags) {
        if (
          ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
        ) {
          datetags[key] = totalTags[key]
        } else {
          tags[key] = totalTags[key]
        }
      }
      Object.keys(datetags).forEach((key) => {
        datetags[key].forEach((item) => {
          if (dateArr[key]) {
            dateArr[key].push({
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            })
          } else {
            dateArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.label,
                tag_type: item.tag_type
              }
            ]
          }
        })
      })
      Object.keys(tags).forEach((key) => {
        tags[key].forEach((item) => {
          if (tagsArr[key]) {
            tagsArr[key].push({
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            })
          } else {
            tagsArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.value,
                tag_label: item.label
              }
            ]
          }
        })
      })
      for (const key in dateArr) {
        if (dateArr[key].length === 0) {
          delete dateArr[key]
        }
      }
      for (const key in tagsArr) {
        if (tagsArr[key].length === 0) {
          delete tagsArr[key]
        }
      }
      if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
        toast.error('Please select atleast one tag')
      } else {
        setShowTagDrawer(false)
        setIsMerge('loadingTag')
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Tagging Selected Projects'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedData.map((item) => {
          return {
            id: item,
            file_type: 'project'
          }
        })
        const req = {
          documents,
          action: 'tags',
          data: { tags: tagsArr, date_tags: dateArr }
        }
        const res = await multiDocuments(req)
        toast.dismiss(toastId)
        if (res.status === 200) {
          const { new_tags } = res.data
          const newTagPayload = {}
          new_tags.forEach((item) => {
            if (!newTagPayload[item.key]) {
              newTagPayload[item.key] = []
            }
            newTagPayload[item.key].push(item)
          })
          Object.keys(newTagPayload || {}).forEach((key) => {
            dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
          })

          fetchMoreData(page)
          const tagsFormated = {}
          const keysToRemove = []
          Object.keys(tagsArr || {}).forEach((key) => {
            keysToRemove.push(key)
            tagsArr[key].forEach((item) => {
              if (tagsFormated[key]) {
                tagsFormated[key].push({
                  tag_key: key,
                  tag_value: item.tag_label,
                  id: item.tag_value
                })
              } else {
                tagsFormated[key] = [
                  {
                    tag_key: key,
                    tag_value: item.tag_label,
                    id: item.tag_value
                  }
                ]
              }
            })
          })
          Object.keys(datetags || {}).forEach((key) => {
            const values = datetags[key]
            keysToRemove.push(key)
            const { data = [] } = tagsCenterStateTags[key] || {}
            values.forEach((item) => {
              const tag = data.find((element) => {
                return element.value === item.label
              })
              if (tag) {
                if (tagsFormated[key]) {
                  tagsFormated[key].push({
                    tag_key: key,
                    tag_value: tag.value,
                    id: tag.id
                  })
                } else {
                  tagsFormated[key] = [
                    {
                      tag_key: key,
                      tag_value: tag.value,
                      id: tag.id
                    }
                  ]
                }
              } else {
                const newTag = new_tags.find((element) => {
                  return element.value === item.label
                })
                if (newTag) {
                  if (tagsFormated[key]) {
                    tagsFormated[key].push({
                      tag_key: key,
                      tag_value: newTag.value,
                      id: newTag.id
                    })
                  } else {
                    tagsFormated[key] = [
                      {
                        tag_key: key,
                        tag_value: newTag.value,
                        id: newTag.id
                      }
                    ]
                  }
                }
              }
            })
          })
          selectedData.forEach((id) => {
            const index = projectList.findIndex((item) => item.id === id)
            if (index === -1) return null
            const oldTags = projectList[index].tags
            const tagsDict = {}
            oldTags.forEach((id) => {
              const item = tagState[id]
              if (tagsDict[item.key]) {
                tagsDict[item.key].push(id)
              } else {
                tagsDict[item.key] = [id]
              }
            })
            Object.keys(tagsFormated).forEach((key) => {
              tagsDict[key] = tagsFormated[key].map((item) => item.id)
            })
            const newTags = []
            Object.keys(tagsDict).forEach((key) => {
              newTags.push(...tagsDict[key])
            })
            const payload = {
              id,
              tags: newTags
            }
            dispatch(updateProjectTag(payload))
          })
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
        }
      }
    }
  }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleSearchQuery = (event, value = null) => {
    if (event?.keyCode === 13) {
      trackEvent('CLIENT_SEARCHED', 'SUCCESS', {}, { search_value: value })
      setIsSearchReloadRequired(true)
      setLoading(true)
      setPage(1)
      dispatch(
        fetchClientsList(
          {
            page_num: 1,
            page_size: 20,
            keyword: value,
            alphabetical: alphabetKey
          },
          'fetch',
          (data) => {
            setRows(data?.clients)
            setLoading(false)
            setMeta({
              pageSize: data?.page_size,
              totalCount: data?.total_count,
              page: data?.page_num
            })
          }
        )
      )
    }
  }

  // const handlePDFViewer = async (index) => {
  //   const pageNoList = [
  //     currentClient?.page_number,
  //     ...currentClient?.variation_list?.map((item) => item?.page_number)
  //   ]
  //   const proposalIdList = [
  //     currentClient?.proposal_id,
  //     ...currentClient?.variation_list?.map((item) => item?.proposal_id)
  //   ]
  //   const newPath = `/view/pdf/${proposalIdList?.[index]}/1`
  //   window.open(newPath, '_blank')
  // }

  const handleMerge = () => {
    if (selectedData.length < 2) {
      toast.error('Please select atleast two person')
      return
    }

    trackEvent(mixpanelEvents.CLIENT_BULK_MERGE_CLICKED, 'SUCCESS', {}, {})

    setShowMergeModal(true)
    // return
  }

  let extraButtons = []

  if (isSuperUser) {
    extraButtons = [
      {
        text: 'clear',
        condition: searchValue !== '',
        onClick: () => {
          setSearchValue('')
          if (isSearchReloadRequired) {
            setIsSearchReloadRequired(false)
            setLoading(true)
            setPage(1)
          }
        },
        icon: '',
        tooltip: 'clear'
      },
      // {
      //   text: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `,
      //   condition: true,
      //   onClick: handleMerge,
      //   icon: '',
      //   tooltip: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `
      // },
      {
        text: 'cancel',
        condition: isMerge === true,
        onClick: () => {
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
        },
        icon: '',
        tooltip: 'cancel'
      }
    ]
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  const getProposalNames = async () => {
    if (proposalNameIDList.length > 0) {
      return
    }
    const proposalRes = await getAllProposalNames()
    if (proposalRes.status === 200) {
      const { proposal_names } = proposalRes.data
      const formattedProposals = proposal_names.map((proposal) => {
        return {
          label: proposal.name,
          value: proposal.id
        }
      })
      setProposalNameIDList(formattedProposals)
    }
  }

  const handleCreateClientGroup = async () => {
    setCreateClientLoading(true)
    const body = {
      client_group_name: clientGroupName,
      proposal_id_list: proposalList
    }
    const callback = () => {
      toast.success('Client created successfully')
      fetchMoreData(page)
      setShowClientGroupModal(false)
      setClientGroupName('')
      setProposalList([])
      setCreateClientLoading(false)
    }
    const fallback = () => {
      toast.error('Unable to create client group')
      setCreateClientLoading(false)
    }

    const res = await createClient(body)

    if (res.status === 200) {
      callback()
    } else {
      fallback()
    }
  }

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => setShowTagDrawer(false)}
      >
        <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
          {selectedData.length === 1 ? (
            <TagCenterUpdate
              id={selectedData?.[0]}
              filterTags="clientgroup"
              document_type={'clientgroup'}
              onUpdateCallback={() => {
                // onUpdateCallback
                setShowTagDrawer(false)
                fetchMoreData(page)
                setSelectedData([])
              }}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          ) : (
            <TagCenterUpdate
              // id={selectedPeople?.[0]}
              isOnlySelect={true}
              filterTags="clientgroup"
              // document_type="masterresume"
              selectCallback={(e) => handleMultiTags(e)}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          )}
        </Box>
      </Drawer>
    )
  }

  // if (loading) {
  //   return <div className="h-screen grid place-content-center">Loading...</div>
  // }

  if (clientId && currentClient) {
    return (
      <ClientResult
        currentClient={currentClient}
        data={currentClientData}
        openProject={openClient}
        handleCloseProject={handleCloseClient}
        s3Obj={s3Obj}
        openType={'tab'}
        thumbnail_url_list={topProposalScreenshot}
        refresh={fetchSingleData}
        screenshotIndex={screenshotIndex}
        originProposalNames={originProposalNames}
        // handlePDFViewer={handlePDFViewer}
        topProposalScreenshotOrigin={topProposalScreenshotOrigin}
        topVariatonList={topVariatonList}
        setTopVariatonList={setTopVariatonList}
        singleData={singleData}
        setSingleData={setSingleData}
      />
    )
  }

  return (
    <Container>
      {showSearch && !singleData && (
        <SectionFixed>
          {showSearch && (
            <div
              className="flex justify-between items-center gap-2"
              style={{
                padding: '10px 18px'
              }}
            >
              <AlphabetSearch
                source="client"
                alphabetKey={alphabetKey}
                setAlphabetKey={setAlphabetKey}
                meta={meta}
              />

              <div className="flex gap-2 items-center">
                {isSuperUser && (
                  <Button2
                    secondary
                    onClick={() => {
                      getProposalNames()
                      setShowClientGroupModal(true)
                    }}
                  >
                    <AddIcon className="size-4" />
                    Client
                  </Button2>
                )}
                <TagFilter
                  showButton
                  source="client"
                  showTags={false}
                  tags={tagsOptions}
                  disabled={loading}
                  selectedTags={selectedTags}
                  onChange={handleTagsValueChange}
                  filterIcon={
                    _.isEmpty(selectedTags) && (
                      <Button2 secondary>
                        <FilterListIcon
                          className="size-4"
                          sx={{
                            height: '1rem',
                            width: '1rem'
                          }}
                        />
                        Filter
                      </Button2>
                    )
                  }
                  clearFilter={
                    !_.isEmpty(selectedTags) && (
                      <Button2 secondary onClick={() => refresh()}>
                        <CrossIcon className="size-4 text-grey-500 " />
                        clear filter
                      </Button2>
                    )
                  }
                />
                <SearchNew
                  value={searchValue}
                  onChange={(value) => handleSearch(value)}
                  onClear={() => {
                    handleSearch('')
                    handleSearchQuery({ keyCode: 13 }, '')
                  }}
                  onEnter={(value) => {
                    handleSearch(value)
                    handleSearchQuery({ keyCode: 13 }, value)
                  }}
                />
              </div>
            </div>
          )}
        </SectionFixed>
      )}
      <Section overFlow>
        <div className={tableContainer}>
          {openClient ? (
            <></>
          ) : (
            <>
              <TagFilter
                source="client"
                showButton={false}
                showTags
                tags={tagsOptions}
                // disabled={loading || isUserViewer}
                selectedTags={selectedTags}
                onChange={handleTagsValueChange}
              />
              <HeadlessTable
                leftAlign={true}
                columns={columns}
                meta={meta}
                data={rows}
                loading={loading}
                fetchPage={(page) => {
                  setPage(page)
                }}
                onRowClick={(row) => {
                  navigateOpenClient(null, row)
                }}
                selectedData={selectedData}
                handleSelectedData={(row) => {
                  console.log('selectedData', selectedData, row)
                  if (selectedData.includes(row.id)) {
                    setSelectedData(
                      selectedData.filter((item) => item !== row.id)
                    )

                    setSelectedRowData(
                      selectedRowData.filter((item) => item.id !== row.id)
                    )
                  } else {
                    setSelectedData([...selectedData, row.id])

                    setSelectedRowData([...selectedRowData, row])
                  }
                }}
                unSelectAll={() => {
                  setSelectedData([])
                  setSelectedRowData([])
                }}
              />

              <Zoom in={selectedRowData.length > 0}>
                <div
                  className="bg-grey-800 p-2 text-white absolute rounded-full flex items-center justify-center "
                  style={{
                    left: '35%',
                    bottom: '150px',
                    padding: '6px',
                    width: '25%',
                    boxShadow: 'rgb(180 180 180) 5px 6px 20px 7px'
                  }}
                >
                  <Button2
                    secondary
                    noOutline
                    onClick={() => {
                      setShowBulkModal(true)
                      trackEvent(
                        mixpanelEvents.CLIENT_BULK_UPDATE_CLICKED,
                        'SUCCESS',
                        {},
                        {}
                      )
                    }}
                    dark={true}
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      borderRadius: '100px'
                    }}
                  >
                    <PropertiesIcon className="size-4 " />
                    Update
                  </Button2>
                  <Button2
                    dark={true}
                    secondary
                    noOutline
                    onClick={() => handleMerge()}
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      borderRadius: '100px'
                    }}
                  >
                    <MergeIcon className="size-4 " />
                    Merge
                  </Button2>
                  <Button2
                    dark={true}
                    secondary
                    noOutline
                    onClick={() => {
                      setShowTagDrawer(true)
                      trackEvent(
                        mixpanelEvents.CLIENT_BULK_TAG_CLICKED,
                        'SUCCESS',
                        {},
                        {}
                      )
                    }}
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      borderRadius: '100px'
                    }}
                  >
                    <Label className="size-4 " />
                    Tag
                  </Button2>
                </div>
              </Zoom>
            </>
          )}
        </div>
      </Section>

      {showBulkModal && (
        <BulkUpdateModal
          s3Obj={s3Obj}
          type={'client'}
          onSuccess={() => {
            fetchMoreData(page)
            setShowBulkModal(false)
            setSelectedData([])
            setSelectedRowData([])
          }}
          onClose={() => setShowBulkModal(false)}
          selectedList={selectedRowData}
          isOpen={showBulkModal}
        />
      )}

      {showMergeModal && (
        <GenericMergeModal
          isOpen={showMergeModal}
          onClose={() => setShowMergeModal(false)}
          selectedData={selectedRowData}
          type="client"
          onMerge={() => {
            fetchMoreData(page)
            setShowMergeModal(false)
            setSelectedData([])
            setSelectedRowData([])
          }}
        />
      )}

      {showClientGroupModal && (
        <Dialog
          maxWidth="lg"
          TransitionComponent={Transition}
          keepMounted
          open={showClientGroupModal}
          onClose={() => setShowClientGroupModal(false)}
          id="dialog-container"
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '28px',
              width: '500px',
              padding: '24px',
              maxHeight: '450px',
              overflow: 'hidden',
              overflowY: 'auto'
              // height: '110px'
            }
          }}
        >
          <div className="">
            <div className="text-base mb-3">Add new client</div>
            <div className="mb-5 flex flex-col justify-center w-full">
              <div className="w-full">
                <span className="text-xs text-grey-600 ml-1">Name</span>
                <TextField
                  fullWidth
                  label=""
                  id="fullWidth"
                  variant="outlined"
                  value={clientGroupName}
                  onChange={(e) => setClientGroupName(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      fontSize: '13px'
                    }
                  }}
                />
              </div>
              <div className="text-left mt-2">
                <span className="text-xs text-grey-600 ml-1">Proposals</span>
                <div className="mt-1">
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={proposalList}
                    onChange={(event) => {
                      const {
                        target: { value }
                      } = event
                      setProposalList(
                        typeof value === 'string' ? value.split(',') : value
                      )
                    }}
                    input={
                      <OutlinedInput
                        label="Tag"
                        style={{
                          fontSize: '13px'
                        }}
                      />
                    }
                    renderValue={(selected) =>
                      selected.length +
                      (selected.length > 1 ? ' proposals' : ' proposal')
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 'auto'
                        }
                      },
                      container: document.getElementById('dialog-container')
                    }}
                    sx={{
                      width: '100%',
                      fontSize: '13px',
                      '& .MuiSelect-select': {
                        padding: '8px 16px',
                        fontSize: '13px'
                      }
                    }}
                  >
                    {proposalNameIDList.map((name) => (
                      <MenuItem
                        key={name.value}
                        value={name.value}
                        sx={{
                          fontSize: '13px'
                        }}
                      >
                        <Checkbox
                          checked={proposalList.includes(name.value)}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: '13px' }
                          }}
                        />
                        <ListItemText
                          primary={name.label}
                          primaryTypographyProps={{
                            fontSize: '13px'
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex">
              <Button2
                onClick={() => {
                  setShowClientGroupModal(false)
                }}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  borderRadius: '28px',
                  color: 'red'
                }}
              >
                Cancel
              </Button2>

              <Button2
                secondary
                onClick={() => {
                  handleCreateClientGroup()
                }}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  borderRadius: '28px'
                }}
                disabled={
                  !clientGroupName ||
                  proposalList.length === 0 ||
                  createClientLoading
                }
              >
                {createClientLoading ? 'Creating...' : 'Create'}
              </Button2>
            </div>
          </div>
        </Dialog>
      )}

      {renderTagDrawer()}
    </Container>
  )
}

export default ClientList
